import React from "react";
import { Row, Col } from "react-flexbox-grid";
// SCSS
import "./hero.scss";

//Assets
// import HeroImage from '../../assets/hero/hero-image.png';
//Components
// import Button from '../ui-components/button/button';

const hero = () => (
  <div className="hero" id="hero">
    <div className="wrapper">
      <Row>
        <Col md={12} lg={6}>
          <div className="hero-info">
            <h1 className="weight800 font40">
            Consulte-nos Sobre Seguros odonto
            </h1>
          
            <p className="font12">Sua escolha de hoje é o bem-estar de amanhã. O SulAmérica Odonto possui três opções de planos, que oferecem
diversas coberturas adicionais ao Rol da ANS, além de muitos
benefícios. Conheça as condições especiais para contratação
do SulAmérica Odonto PME e PME Mais em conjunto com o SulAmérica Saúde.</p>
        
            <a href="https://sulamericaodonto.com.br/robertosergio" target="_blank" className="buttonHero">Contrate agora via Portal</a>
          </div>
        </Col>
        <Col md={12} lg={6}>
          <div className="hero-image">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/pk4UhDI8iBA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </Col>
      </Row>
    </div>
  </div>
);

export default hero;
