import React from "react";
import Swiper from "react-id-swiper";
// SCSS
import "./blog.scss";
import "swiper/css/swiper.css";
// Assets
import Odonto1 from "../../assets/blog/odonto_1.jpg";
import Odonto2 from "../../assets/blog/odonto_2.jpg";
import Odonto3 from "../../assets/blog/odonto_4.jpg";
// Components
// import Title from "../ui-components/title/title";
import BlogBox from "./blogBox";

class Blog extends React.Component {
  state = {
    // LIST ARRAY OF BLOG STORIES
    stories: [
      { 
        image: Odonto1,
        id: "1",
        title: "SulAmérica Odonto PME 2 a 29 vidas",
        description: "Sua escolha de hoje é o bem-estar de amanhã. Quem tem SulAmérica Saúde Integral abre mais sorrisos.",
        link: "folhetos/FolhetoOdontoPME.pdf",
      },
      {
        image: Odonto2,
        id: "2",
        title: "SulAmérica Odonto 2 a 99 vidas",
        description: "Com você para que sempre possa escolher sorrir. Corretor de Seguros - 61 9 9952.9302 Roberto Sergio. email - rssaude.seguros@gmail.com",
        link: "folhetos/FolhetoOdonto2.pdf",
      },
      { 
        image: Odonto3,
        id: "3",
        title: "Odonto Fácil",
        description: "Confira os beneficios e vantages que fazem o cliente SulAmérica Odonto sorrir",
        link: "folhetos/Folheto_OdontoFacil.jpg",
      }
    ],
  };

  render() {
    // BLOG STORIES RENDER
    let storiesRender = null;
    if (this.state.stories) {
      storiesRender = this.state.stories.map((story) => (
        <div key={story.id}>
          <a href={story.link}><BlogBox article={story} /></a>
        </div>
      ));
    }
    // OPTIONS FOR BLOG SLIDER
    const params = {
      grabCursor: true,
      slidesPerView: 1,
      spaceBetween: 10,
      loop: true,
      breakpoints: {
        1200: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
      },
    };

    return (
      <div className="blog" id="blog">
        <div className="wrapper">
          <div className="padding30">
            <Swiper {...params}>{storiesRender}</Swiper>
          </div>
        </div>
      </div>
    );
  }
}

export default Blog;
