import React from 'react';
import { Link } from "react-scroll";
 // SCSS
import './navbar.scss';
// Assets
import LogoImg from '../../assets/navbar/logo.jpg';
import MobileMenuIcon from '../../assets/navbar/mobile-menu.svg';

const desktopNav = (props) => (
  <nav className={`Navbar ${!props.userIsScrolled ? "extraLargeNavbar" : ""}`}>
    <div className="wrapper flex-s-between">
      <div>
        <Link to="hero" spy={true} smooth={true} offset={0} duration={500}>
          <img src={LogoImg} alt="logo" className="pointer" />
        </Link>
      </div>
      <div className="mobile__menu" onClick={props.mobileMenuOpen}>
        <img src={MobileMenuIcon} alt="menu" />
      </div>
      <div className="desktop__menu">
        <ul className="flex-s-between">
        < li>
            <Link activeClass="active-link" to="blog" spy={true} smooth={true} offset={-70} duration={500}>
             PLANOS ODONTO
            </Link>
          </li>
          <li>
            <a href="mailto:rssaude.seguros@gmail.com">CONTATO</a>
           
          </li>

          <li>
          
          <div className="contact-nav">
           
              <p className="nome-nav"><b>Roberto Sergio</b></p>
              <p>Corretor de Seguros - Desde 1991</p>
              <span>Seguros Todos os Ramos</span><br />
              <small>SUSEP/MF. 100254533</small>

          </div>
         </li>
          <li>
         
          <div className="contact-nav">
                
              <p className="color-nav">61.&nbsp;3541-9302</p>
              <p className="color-nav">61.&nbsp;9952-9302</p>
              <a href="mailto:rssaude.seguros@gmail.com">rssaude.seguros@gmail.com</a>
              <p>Brasília - DF</p>
                
           </div>
         
          </li>
        
        </ul>
      </div>
    </div>
  </nav>
);

export default desktopNav;