import React from "react";
// SCSS
import "./teamInfo.scss";

const about = () => (
  <div className="team__info flex-center">
    <div>
      <h4 className="font20 weight800">RS Seguros</h4>
      <p className="font12">• Planos a partir de 3 pessoas com diferenciais, para atender os diversos níveis dentro de sua empresa.  </p>
      <p className="font12"> • Rede referenciada nacional com mais de 20.000 prestadores. clínicas, laboratórios, hospitais e muito mais qualidade e tecnologia.  </p>
      <p className="font12"> • Opção de reembolso no Brasil e Exterior.  </p>
      <p className="font12"> • Assistência 24 hrs, vários serviços de apoio ao segurado, e RH da empresa através de tecnicos especializados.  </p>
       <p className="font12">  • Produtos e serviços prestados pelas melhores seguradoras.  </p>
      <p className="font12">  • Apresentamos a melhor solução em saúde para sua empresa.  </p>

          
    </div>
  </div>
);

export default about;
