import React from "react";
import { Row, Col } from "react-flexbox-grid";
import "./about.scss";
// Components
import TeamBox from './teamBox';
import TeamInfo from "./teamInfo";
// import Title from "../ui-components/title/title";
// Assets
import Person01 from "../../assets/about/sobrenos_odonto.png";


const about = () => (
  <div id="about">
    <div className="wrapper">
      {/* <Title title="RS Seguros" /> */}
      <Row>
      <Col md={12} lg={8}>
          <TeamInfo />
        </Col>
        <Col md={12} lg={4}>
          <TeamBox avatar={Person01} />
        </Col>
    
        
      </Row>
    </div>
  </div>
);

export default about;
